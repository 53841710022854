const itemsMenu = [
  {
    name: 'recipes',
    item: 'Recetas',
  },

  {
    name: 'ingredients',
    item: 'Ingredientes',
  },
  /*   {
    name: 'family',
    item: 'Familia',
  }, */
  {
    name: 'products',
    item: 'Productos',
  },
];

export default itemsMenu;
