<template>
  <div class="main-nav">
    <nav>
      <div class="nav-logo">
        <img
          src="/images/logos/logo-white.svg"
          alt="Viene Bien"
          title="Viene Bien"
        />
      </div>
      <ul :class="{ showItems }">
        <router-link
          v-for="(link, index) in itemsMenu"
          :key="index"
          :to="{ name: link.name }"
        >
          <li>
            <a>
              {{ link.item }}
            </a>
          </li>
        </router-link>
        <button @click="logoutUser" class="btn btn-secondary btn-medium">
          Cerrar sesión
        </button>
      </ul>
      <button @click="toggleNavbar" class="btn btn-white btn-bar">
        <i :class="[showItems ? 'far fa-times-circle' : 'fas fa-bars']"></i>
      </button>
    </nav>
  </div>
</template>

<script>
import { ref } from 'vue';

import itemsMenu from '@/modules/cms/data/itemsMenu';
import useAuth from '@/modules/auth/composables/useAuth';

export default {
  setup() {
    const showItems = ref(false);

    const { logoutUser } = useAuth();

    const toggleNavbar = () => {
      showItems.value = !showItems.value;
    };

    return {
      showItems,
      toggleNavbar,
      itemsMenu,
      logoutUser,
    };
  },
};
</script>
